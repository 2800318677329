import React from "react"
import rehypeReact from "rehype-react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import get from "lodash/get"
import { Flex, Heading, Text, Icon, Image, Box, Link } from "@chakra-ui/react"
import { FaFacebook, FaTwitter } from "react-icons/fa"
import Layout from "../components/layout"
import BlogAnchor from "../components/blog-anchor"
import Video from "../components/video"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { a: BlogAnchor, video: Video },
}).Compiler

const article = {
  root: {
    mt: "44px",
    pt: "39px",
    pb: "60px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderTop: "1px solid #E0E0E0",
  },
  next: {
    color: "#595C5F",
    fontSize: "18px",
    lineHeight: "266%",
    letterSpacing: "1px",
  },
  heading: {
    mt: "16px",
    color: "#1B263F",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    fontWeight: "bold",
  },
  date: {
    mt: "24px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontSize: "12px",
    lineHeight: "266%",
    color: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#1B263F",
  },
  link: {
    mt: "16px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#3399FF",
  },
}

const styles = {
  "inner-box": {
    display: "flex",
    flexDirection: "column",
    alignItems: { base: "flex-start", md: "flex-start", lg: "center" },
    px: { base: "24px", md: "24px", lg: "10%" },
    pt: "44px",
  },
  header: {
    width: "100%",
    flexDirection: "column",
  },
  tag: {
    color: "#3399FF",
    fontSize: "18px",
    lineHeight: { base: "266%", md: "266%", lg: "110%" },
    letterSpacing: { base: "1px", md: "1px", lg: "-0.0015em" },
  },
  heading: {
    mt: { base: "12px", md: "12px", lg: "24px" },
    color: "#1B263F",
    fontSize: { base: "24px", md: "24px", lg: "48px" },
    lineHeight: { base: "32px", md: "32px", lg: "56px" },
    fontWeight: "bold",
  },
  "header-inner-box": {
    width: "100%",
    mt: { base: "12px", md: "12px", lg: "32px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    justifyContent: "space-between",
  },
  "meta-box": {
    alignItems: { base: "flex-start", md: "flex-start", lg: "center" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    width: { base: "100%", md: "100%", lg: "auto" },
  },
  "logo-box": {
    mt: { base: "32px", md: "32px", lg: "0px" },
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
    alignItems: "center",
    width: { base: "100%", md: "100%", lg: "auto" },
  },
  divider: {
    display: { base: "none", md: "none", lg: "block" },
    borderColor: "#1B263F",
    mx: "16px",
    height: "24px",
  },
  author: {
    color: "#1B263F",
    fontSize: "16px",
    lineHeight: "150%",
  },
  date: {
    color: "#1B263F",
    fontSize: "16px",
    lineHeight: "150%",
  },
  twitter: {
    width: "25px",
    height: "20px",
    color: "#1DA1F2",
  },
  fb: {
    borderRadius: "100%",
    ml: "15.5px",
    width: "25px",
    height: "20px",
    color: "#4267B2",
  },
  body: {
    mt: "48px",
    flexDirection: "column",
    height: "100%",
    width: { base: "100%", md: "100%", lg: "726px" },
  },
  image: {
    width: "100%",
    height: "auto",
  },
  blog: {
    mt: "40px",
    fontSize: "16px",
    color: "#000000",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    p: {
      marginTop: "20px",
    },
    "ol, ul": {
      marginTop: "20px",
      paddingLeft: "20px",
    },
    a: {
      color: "#3399FF",
      textDecoration: "underline",
    },
    h1: {
      fontSize: "40px",
      lineHeight: "48px",
      fontWeight: "bold",
      marginTop: "20px",
    },
    h2: {
      fontSize: "32px",
      lineHeight: "40px",
      fontWeight: "bold",
      marginTop: "20px",
    },
    h3: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: "bold",
      marginTop: "20px",
    },
  },
  article,
}

const DefaultImage = props => (
  <Box sx={props.sx}>
    <StaticImage
      src="../images/blog/blog-placeholder.png"
      alt={props.alt}
      placeholder="blurred"
    />
  </Box>
)

const NewsPostTemplate = props => {
  const post = get(props, "data.contentfulNewsPost")
  const next = get(props, "data.next")
  const previous = get(props, "data.previous")
  const [url, setUrl] = React.useState()
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const url = window.location.href
      setUrl(url)
    }
  }, [])

  const article = React.useMemo(() => {
    const hasNext = !!next
    const props = hasNext ? next : previous
    return props
  }, [next, previous])

  return (
    <Layout
      location={props.location}
      title={post.title}
      description={post?.description?.childMarkdownRemark?.excerpt}
      image={post?.heroImage?.gatsbyImageData?.images?.fallback?.src}
    >
      <Box sx={styles["inner-box"]}>
        <Flex sx={styles["header"]}>
          <Text sx={styles.tag}>{post?.tags[0]?.toUpperCase()}</Text>
          <Heading sx={styles.heading}>{post.title}</Heading>
          <Flex sx={styles["header-inner-box"]}>
            <Flex sx={styles["meta-box"]}>
              <Text sx={styles.date}>{post?.publishDate}</Text>
            </Flex>

            <Flex sx={styles["logo-box"]}>
              <Link
                href={`https://www.twitter.com/share?url=${url}`}
                isExternal
              >
                <Icon as={FaTwitter} sx={styles.twitter} />
              </Link>
              <Link
                href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                isExternal
              >
                <Icon as={FaFacebook} sx={styles.fb} />
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Box sx={styles["body"]}>
          {post?.heroImage?.gatsbyImageData ? (
            <Image
              as={GatsbyImage}
              alt={post?.heroImage?.description}
              image={post?.heroImage?.gatsbyImageData}
              sx={styles.image}
              placeholder="blurred"
            />
          ) : (
            <DefaultImage alt={post?.title} sx={styles.image} />
          )}
          <Box sx={styles.blog}>
            {renderAst(post?.body?.childMarkdownRemark?.htmlAst)}
          </Box>
          <Flex alignItems="center" justifyContent="center" my="40px">
            <Link href={`https://www.twitter.com/share?url=${url}`} isExternal>
              <Icon as={FaTwitter} sx={styles.twitter} />
            </Link>
            <Link
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              isExternal
            >
              <Icon as={FaFacebook} sx={styles.fb} />
            </Link>
          </Flex>
          <Flex sx={styles.article.root}>
            <Link
              as={GatsbyLink}
              to={`/news/${article.slug}`}
              sx={styles.article.link}
            >
              <Text sx={styles.article.next}>READ NEXT</Text>
              <Heading sx={styles.article.heading}>{article?.title}</Heading>
            </Link>
            <Text sx={styles.article.date}>{article?.publishDate}</Text>
            <Box
              sx={styles.article.text}
              dangerouslySetInnerHTML={{
                __html: article?.description?.childMarkdownRemark.html,
              }}
            />
            <Link
              as={GatsbyLink}
              to={`/news/${article?.slug}`}
              sx={styles.article.link}
            >
              read more...
            </Link>
          </Flex>
        </Box>
      </Box>
    </Layout>
  )
}

export default NewsPostTemplate

// https://github.com/gatsbyjs/gatsby/pull/33528
export const pageQuery = graphql`
  query NewsPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulNewsPost(slug: { eq: $slug }) {
      slug
      title
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        description
      }
      body {
        childMarkdownRemark {
          htmlAst
          timeToRead
        }
      }
      tags
      description {
        childMarkdownRemark {
          excerpt
        }
      }
    }
    previous: contentfulNewsPost(slug: { eq: $previousPostSlug }) {
      slug
      title
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      description {
        childMarkdownRemark {
          html
        }
      }
    }
    next: contentfulNewsPost(slug: { eq: $nextPostSlug }) {
      slug
      title
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
